import React from 'react';

function ErrorMessage({ errorMessage, className = '' }) {
  if (!errorMessage) return null;

  return (
    <div className={`bg-red-100 dark:bg-red-900/30 border border-red-300 dark:border-red-800 text-red-600 dark:text-red-300 px-4 py-3 rounded-md mb-4 ${className}`}>
      <div className="flex items-start">
        <svg 
          className="w-5 h-5 mr-2 mt-0.5 flex-shrink-0" 
          fill="currentColor" 
          viewBox="0 0 20 20"
        >
          <path 
            fillRule="evenodd" 
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" 
            clipRule="evenodd"
          />
        </svg>
        <span>{errorMessage}</span>
      </div>
    </div>
  );
}

export default ErrorMessage;